@use "sass:math";
$retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';

$grid-columns: 12 !default;
$grid-gutter: units(4);

@mixin grid-column($size, $n) {
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-#{$n} {
    flex-basis: math.div(100%, $grid-columns) * $n;
    max-width: math.div(100%, $grid-columns) * $n;
  }

  .col-offset-0 {
    margin-left: 0;
  }

  .col-offset-#{$n} {
    margin-left: math.div(100%, $grid-columns) * $n;
  }
}

[class^='col-'],
[class*=' col-'] {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

.row {
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 1;
  flex-wrap: wrap;
  margin: 0 -#{$grid-gutter};
}

.no-gutter {
  margin: 0;

  > [class^='col-'],
  [class*=' col-'] {
    padding: 0;
  }
}

@for $i from 1 through $grid-columns {
  @include grid-column(sm, $i);
}
