@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,400;0,600;1,400;1,600');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600');

:root {
  --font-alt-sans: 'Poppins', sans-serif;
  --font-sans: 'Source Sans Pro', sans-serif;
  --font-mono: 'Source Code Pro', monospace;

  /* https://www.figma.com/file/ahsRedo1ws2UEOs0bdbslH/iCIMS-Design-System?node-id=4662%3A14 */
  --icims-default-font-size: 16px;
  --icims-font-size-table-header: 14px;
}
