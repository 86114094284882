// Basis Grotesque Pro

$basisGrotesquePro-path: '/assets/fonts/BasisGrotesquePro/BasisGrotesquePro' !default;

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('#{$basisGrotesquePro-path}-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('#{$basisGrotesquePro-path}-Regular.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('#{$basisGrotesquePro-path}-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}
