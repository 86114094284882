.f-row {
  display: flex;
  flex-direction: row;
}

.f-column {
  display: flex;
  flex-direction: column;
}

.nowrap {
  flex-wrap: nowrap;
}
.wrap {
  flex-wrap: wrap;
}

.x-start {
  justify-content: flex-start;
}
.x-end {
  justify-content: flex-end;
}
.x-center {
  justify-content: center;
}
.x-between {
  justify-content: space-between;
}
.x-around {
  justify-content: space-around;
}

.y-start {
  align-items: flex-start;
}
.y-end {
  align-items: flex-end;
}
.y-center {
  align-items: center;
}
.y-baseline {
  align-items: baseline;
}

.column {
  &.x-start {
    align-items: flex-start;
  }
  &.x-end {
    align-items: flex-end;
  }
  &.x-center {
    align-items: center;
  }
  &.x-baseline {
    align-items: baseline;
  }

  &.y-start {
    justify-content: flex-start;
  }
  &.y-end {
    justify-content: flex-end;
  }
  &.y-center {
    justify-content: center;
  }
  &.y-between {
    justify-content: space-between;
  }
  &.y-around {
    justify-content: space-around;
  }
}

.shrink {
  flex-shrink: 0;
}
