/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

* {
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-family: var(--font-sans);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  overflow: auto;
  color: black;
  background-color: $white;

  line-height: 1;
  min-height: calc(100vh - 20px);
  min-width: calc(100vw - 20px);

  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0 !important;
    }
  }
}

a:link {
  color: black;
}

a:visited {
  color: black;
}

a:active {
  color: black;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.icon {
  line-height: 1;
}

input {
  appearance: none;
}

a {
  text-decoration: none;
}

/* ----------------------------------------------------------------------------------------------------
Super Form Reset
A couple of things to watch out for:
- IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
- The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borders.
- You NEED to set the font-size and family on all form elements
- Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
- You can style the upload button in webkit using ::-webkit-file-upload-button
- ::-webkit-file-upload-button selectors can't be used in the same selector as normal ones. FF and IE freak out.
- IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
- By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs
----------------------------------------------------------------------------------------------------*/

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-size: var(--icims-default-font-size);
  font-family: var(--font-sans);
}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type='search'] {
  -webkit-appearance: textfield;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type='reset'],
input[type='button'],
input[type='submit'] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-----------------------------------------------*/

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}
