/*
 * We are using css variables instead of sass variables. This is a runtime
 * technology while sass is a compile time technology
 *
 * pros:
 *  - We can see the semantic color from debugging tools
 *  - designers have visibility into our styling
 *  - we can reference these variables from javascript and templates
 * cons:
 *  - simplistic static analysis...
 *     https://github.com/csstools/stylelint-value-no-unknown-custom-properties/pull/22
 *  - IE requires a ponyfill
 */

:root {
  --icims-primary-main: rgba(27, 91, 216, 1);
  --icims-primary-light: rgba(20, 111, 235, 1);
  --icims-primary-dark: rgba(33, 58, 185, 1);
  --icims-primary-contrast: rgba(255, 255, 255, 1);
  --icims-primary-contained-hover-background: rgba(19, 64, 151, 1);
  --icims-primary-outlined-hover-background: rgba(27, 91, 216, 0.08);
  --icims-primary-outlined-resting-border: rgba(27, 91, 216, 0.5);

  --icims-text-primary: rgba(0, 0, 0, 0.87);
  /* which secondary is correct? */
  --icims-text-secondary: rgba(0, 0, 0, 0.67);
  /* --icims-text-secondary: rgba(0, 0, 0, 0.54); */
  --icims-text-disabled: rgba(0, 0, 0, 0.38);

  --icims-action-active: rgba(0, 0, 0, 0.54);
  --icims-action-hover: rgba(0, 0, 0, 0.04);
  --icims-action-selected: rgba(0, 0, 0, 0.08);
  --icims-action-disabled: rgba(0, 0, 0, 0.26);
  --icims-action-disabled-solid: rgba(189, 189, 189, 1);
  --icims-action-disabled-background: rgba(0, 0, 0, 0.12);
  --icims-action-focus: rgba(0, 0, 0, 0.12);
  --icims-action-active-hover: rgba(0, 0, 0, 0.72);

  --icims-background-paper: rgba(255, 255, 255, 1);
  --icims-background-default: rgba(239, 242, 243, 1);

  --icims-ui-divider: rgba(0, 0, 0, 0.26);
  --icims-ui-outline-border: rgba(255, 255, 255, 1);
  --icims-ui-standard-input-line: rgba(255, 255, 255, 1);
  --icims-ui-backdrop-overlay: rgba(0, 0, 0, 0.5);
  --icims-ui-rating-active: rgba(255, 180, 0, 1);
  --icims-ui-ai-ml: rgba(13, 164, 181, 1);
  --icims-ui-contrast-background: rgba(48, 56, 65, 1);
  --icims-ui-contrast-action: rgba(138, 199, 255, 1);
  /* 2px solid primary-light */
  --icims-ui-focus-outline: rgba(20, 111, 235, 1);
  --icims-ui-default-selected: rgba(0, 0, 0, 0.16);
  --icims-ui-primary-selected: rgba(27, 91, 216, 0.16);

  --icims-error-main: rgba(156, 9, 9, 1);
  --icims-error-dark: rgba(103, 0, 0, 1);
  --icims-error-light: rgba(212, 71, 50, 1);
  --icims-error-contrast: rgba(255, 255, 255, 1);
  --icims-error-contained-hover-background: rgba(109, 6, 6, 1);
  --icims-error-outlined-resting-border: rgba(156, 9, 9, 0.5);
  --icims-error-outlined-hover-background: rgba(156, 9, 9, 0.08);
  --icims-error-alert-content: rgba(62, 4, 4, 1);
  --icims-error-alert-background: rgba(245, 230, 230, 1);

  --icims-info-main: rgba(10, 78, 154, 1);
  --icims-info-dark: rgba(0, 39, 107, 1);
  --icims-info-light: rgba(81, 121, 203, 1);
  --icims-info-contrast: rgba(255, 255, 255, 1);
  --icims-info-contained-hover-background: rgba(7, 55, 108, 1);
  --icims-info-outlined-resting-border: rgba(10, 78, 154, 0.5);
  --icims-info-outlined-hover-background: rgba(10, 78, 154, 0.08);
  --icims-info-alert-content: rgba(4, 31, 62, 1);
  --icims-info-alert-background: rgba(230, 237, 245, 1);

  --icims-warning-main: rgba(194, 86, 4, 1);
  --icims-warning-dark: rgba(139, 40, 0, 1);
  --icims-warning-light: rgba(251, 133, 58, 1);
  --icims-warning-contrast: rgba(255, 255, 255, 1);
  --icims-warning-contained-hover-background: rgba(136, 60, 3, 1);
  --icims-warning-outlined-resting-border: rgba(194, 86, 4, 0.5);
  --icims-warning-outlined-hover-background: rgba(194, 86, 4, 0.08);
  --icims-warning-alert-content: rgba(78, 34, 2, 1);
  --icims-warning-alert-background: rgba(249, 238, 230, 1);

  --icims-success-main: rgba(0, 133, 86, 1);
  --icims-success-dark: rgba(0, 87, 45, 1);
  --icims-success-light: rgba(128, 194, 170, 1);
  --icims-success-contrast: rgba(255, 255, 255, 1);
  --icims-success-contained-hover-background: rgba(0, 93, 60, 1);
  --icims-success-outlined-resting-border: rgba(0, 133, 86, 0.5);
  --icims-success-outlined-hover-background: rgba(0, 133, 86, 0.08);
  --icims-success-alert-content: rgba(0, 53, 34, 1);
  --icims-success-alert-background: rgba(229, 243, 238, 1);

  --icims-brand-teal-main: rgba(72, 168, 175, 1);
  --icims-brand-teal-light: rgba(186, 214, 217, 1);
  --icims-brand-teal-dark: rgba(28, 112, 125, 1);

  --icims-brand-gold-main: rgba(249, 168, 76, 1);
  --icims-brand-gold-light: rgba(250, 217, 163, 1);
  --icims-brand-gold-dark: rgba(194, 138, 46, 1);

  --icims-brand-red-main: rgba(236, 29, 49, 1);
  --icims-brand-red-light: rgba(247, 176, 161, 1);
  --icims-brand-red-dark: rgba(166, 33, 46, 1);

  --icims-brand-purple-main: rgba(167, 115, 176, 1);
  --icims-brand-purple-light: rgba(212, 196, 222, 1);
  --icims-brand-purple-dark: rgba(115, 89, 125, 1);

  --icims-data-gray-blue-main: rgba(123, 151, 177, 1);
  --icims-data-gray-blue-light: rgba(171, 200, 227, 1);
  --icims-data-gray-blue-dark: rgba(71, 97, 120, 1);

  --icims-data-cyan-main: rgba(0, 161, 194, 1);
  --icims-data-cyan-light: rgba(74, 199, 229, 1);
  --icims-data-cyan-dark: rgba(0, 122, 143, 1);

  --icims-data-purple-main: rgba(128, 109, 217, 1);
  --icims-data-purple-light: rgba(179, 156, 255, 1);
  --icims-data-purple-dark: rgba(78, 66, 167, 1);

  --icims-data-magenta-main: rgba(220, 46, 255, 1);
  --icims-data-magenta-light: rgba(241, 180, 254, 1);
  --icims-data-magenta-dark: rgba(173, 0, 238, 1);

  --icims-data-gold-main: rgba(195, 138, 40, 1);
  --icims-data-gold-light: rgba(249, 186, 88, 1);
  --icims-data-gold-dark: rgba(175, 97, 24, 1);

  --icims-data-green-main: rgba(107, 163, 35, 1);
  --icims-data-green-light: rgba(157, 213, 85, 1);
  --icims-data-green-dark: rgba(48, 109, 2, 1);
}
