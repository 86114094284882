.button {
  border-radius: units(1);
  display: inline-block;
  padding: units(2.75) units(5);
  position: relative;
  text-transform: capitalize;
  text-decoration: none;
  top: 0;
  transition: top 150ms ease-in-out, box-shadow 150ms ease-in-out;
  @include text('14px', 'bold');

  > span {
    margin-right: units(2);
  }

  &:hover {
    box-shadow: 0 units(2.5) units(2.5) rgba(black, 0.1);
    top: units(-0.5);
  }

  &:disabled {
    background-color: $white !important;
    color: rgba($disabled-text, 0.5) !important;
    cursor: default;

    &:hover {
      box-shadow: none;
      top: 0;
    }
  }
}

