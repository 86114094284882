// brand colors
$orange: #ff8356;
$red: #ff6469;
$burgundy: #a50029;
$yellow: #fce850;
$turquoise: #45cad6;
$turquoise-darker: #005d9c;
$turquoise-dark: #39b9c4;
$turquoise-light: #8ee7ef;
$turquoise-accent: #12ffe8;
$blue-dark: #134074;
$blue-darker: #13315c;
$blue-light: #005d9c;
$grey-dark: #495057;
$grey-medium: #9899b2;
$grey-light: #f1f3f5;
$grey-lighter: #f3f4fa;
$grey-lightest: #fbfcfd;
$black: #000;
$white: #fff;

$disabled-text: #8da9c4;

$txt-base: #2e43ab;
$txt-info: #8dc4b1;
$txt-social: #e200ff;
$txt-search-bar: #495057;
$text-alert: #ff0202;
$txt-question-title: #0a7893;
$bg-button: #44bc62;
$bg-pills: #f5f5f5;
$bg-header: #00b6fd;
$bg-search-bar: #c8c8c8;
$focus-color: #101244;
$accent-color: #29dbef;

$border-color: #dde2e6;
$border-color-hover: #848e97;
$border-color-focus: #101244;

$shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
