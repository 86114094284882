.mat-mdc-snack-bar-container {
  text-align: center;
  // --mat-mdc-snack-bar-button-color: #ffffff;
  // --mdc-snackbar-supporting-text-color: #ffffff;

  &.default-snackbar {
    --mdc-snackbar-container-color: #303841;
    position: absolute;
    bottom: 16px;

    .mdc-snackbar__surface {
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12),
        0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
    }
  }
}
