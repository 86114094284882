$font-base: 'Basis Grotesque Pro', sans-serif;

$rem-size: 16px;

$font-sizes: (
  '28px': 1.75rem,
  '20px': 1.25rem,
  '16px': 1rem,
  '14px': 0.875rem,
  '12px': 0.75rem,
  '10px': 0.62rem,
);

$font-line-heights: (
  '28px': 34px,
  '20px': 32px,
  '16px': 22px,
  '14px': 19px,
  '12px': 15px,
  '10px': 13px,
);

$font-weights: (
  'light': 300,
  'regular': 500,
  'bold': 700,
);

@mixin text($size, $weight: 'regular', $aliased: 'true') {
  font-size: map-get($font-sizes, $size);
  line-height: map-get($font-line-heights, $size);
  font-weight: map-get($font-weights, $weight);

  @if $aliased == 'true' {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
}
