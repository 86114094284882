.pill {
  color: var(--icims-text-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
  padding: 7px 12px;
  border-radius: 4px;
  background: $bg-pills;
}
