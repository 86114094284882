@import './styles/base';
@import './styles/reset';
@import './styles/flex-utilities';
@import './styles/fonts';
@import './styles/animations';
@import './styles/grid-flex';
@import './styles/component-button';
@import './styles/component-pill';
@import '~@angular/cdk/overlay-prebuilt.css';

@import './styles/_icims-colors';
@import './styles/_icims-fonts';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
